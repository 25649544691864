import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

/**
 * Change lessons to sessions based on the GICEA content
 *
 * @param {object} training - The training object
 * @return {string}
 */
export const LessonsToSessions = (training) => {
  const { t: __ } = useTranslation();
  const { organisateur, horaires, duree, horairePrevu } = training;
  let numberOfSessions = 0;
  let realDuree = duree;

  if (
    organisateur.nom &&
    (organisateur.nom.startsWith('Université Populaire') ||
      organisateur.nom.startsWith('UniPop') ||
      organisateur.nom.startsWith('Unipop'))
  ) {
    if (horaires && horaires.length > 0) {
      const sessionDuration = horaires[0].duree;
      const [hours, minutes] = sessionDuration.split('h').map(Number);
      const totalSessionMinutes = hours * 60 + minutes;
      const lessonsCount = parseInt(duree.split(' ')[0]);
      const lessonDurationMinutes = 50;
      const lessonsPerSession = totalSessionMinutes / lessonDurationMinutes;
      numberOfSessions = Math.ceil(lessonsCount / lessonsPerSession);

      if (numberOfSessions > 0) {
        if (numberOfSessions === 1) realDuree = `${numberOfSessions} ${__('templates.trainings.session')}`;
        else realDuree = `${numberOfSessions} ${__('templates.trainings.sessions')}`;
      }
    } else {
      const parsedHorairePrevu = ParseHorairePrevu(horairePrevu);
      numberOfSessions = parsedHorairePrevu.length;

      if (numberOfSessions > 0) {
        if (numberOfSessions === 1) realDuree = `1 ${__('templates.trainings.session')}`;
        else realDuree = `${numberOfSessions} ${__('templates.trainings.sessions')}`;
      } else {
        realDuree = `1 ${__('templates.trainings.session')}`;
      }
    }
  }
  return realDuree;
};

/**
 * Update institutions name based on the GICEA content
 *
 * @param {object|string} trainingOrName - The training object
 * @return {string}
 */
export const UpdateInstitutions = (trainingOrName) => {
  const { t: __ } = useTranslation();
  let institutionName = '';

  if (typeof trainingOrName === 'string') {
    institutionName = trainingOrName;
  } else if (typeof trainingOrName === 'object' && trainingOrName.organisateur) {
    institutionName = trainingOrName.organisateur.nom;
  }

  if (institutionName && institutionName.startsWith('Université Populaire')) {
    institutionName = institutionName.replace('Université Populaire ', '');
    institutionName = `${__('templates.trainings.UniPop')} - ${__('templates.trainings.siteOf')} ${institutionName}`;
  }
  return institutionName;
};

/**
 *
 * @param {string} horairePrevu
 * @returns {array}
 */
export const ParseHorairePrevu = (horairePrevu) => {
  return horairePrevu.split(/,\s*|\n\s*/).map((entry) => entry.trim());
};

LessonsToSessions.propTypes = {
  training: PropTypes.object.isRequired,
};

UpdateInstitutions.propTypes = {
  trainingOrName: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

ParseHorairePrevu.propTypes = {
  horairePrevu: PropTypes.string.isRequired,
};

UpdateInstitutions.defaultProps = {
  trainingOrName: '',
};
