import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

/** Redux */
import { saveList as saveTrainingsList, saveError as saveTrainingsError } from '../redux/trainingsSlice';

/** Hooks */
import { useCsrfToken } from './useCsrfToken';

/** Utils */
import { responseStatus } from '../util/Fetch';

/**
 * useFetchTrainings Hook
 *
 * Fetch the trainings list from the app and save it to Redux.
 * If an error occured, the error message is saved to Redux.
 *
 * @return {function}  The hook callback function
 */
const useFetchTrainings = () => {
  const dispatch = useDispatch();

  /** Get a token and a wp_referer */
  const { csrfToken, wpReferer } = useCsrfToken('get-trainings');

  /**
   * fetchTrainings memoized callback function
   */
  const fetchTrainings = useCallback(() => {
    if (csrfToken !== '' && wpReferer !== '') {
      fetch(`${process.env.REACT_APP_THEME_URL}resources/get-trainings.php`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-CSRF-TOKEN': csrfToken,
          'X-WP-REFERER': wpReferer,
        },
      })
        .then(responseStatus)
        .then((response) => response.json())
        .then((response) => dispatch(saveTrainingsList(response)))
        .catch((error) => dispatch(saveTrainingsError(error.message)));
    }
  }, [csrfToken, wpReferer, dispatch]);

  /** Return the hook callback function */
  return fetchTrainings;
};

export { useFetchTrainings };
export default useFetchTrainings;
