import PropTypes from 'prop-types';
import { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMenuTriggerState } from '@react-stately/menu';
import { useMenuTrigger } from '@react-aria/menu';
import { useOverlay, DismissButton } from '@react-aria/overlays';
import { useButton } from '@react-aria/button';
import { FocusScope } from '@react-aria/focus';
// import { Item } from '@react-stately/collections';
// import { kebabCase } from 'lodash';

/** Redux */
import { selectIsTrainingsListLoaded, selectSearchedSlice } from '../../../redux/trainingsSlice';

/** Hooks */
// import useNavigateWithLoader from '../../../hooks/useNavigateWithLoader';
import useCloseWithTransition from '../../../hooks/useCloseWithTransition';

/** Components */
import FieldSearch from '../../forms/FieldSearch';
// import Menu from '../../Menu';
// import Link from '../../Link';
import Button from '../../Button';
import TrainingsTable from '../../TrainingsTable';
import Pagination from '../../Pagination';

const FormSearch = () => {
  const { t: __ } = useTranslation();
  const buttonRef = useRef();
  const menuState = useMenuTriggerState({});
  const { menuTriggerProps, menuProps } = useMenuTrigger({}, menuState, buttonRef);
  const { buttonProps } = useButton(menuTriggerProps, buttonRef);

  /** Search */
  const [searchQuery, setSearchQuery] = useState('');
  const searchResults = useRef([]);
  searchResults.current = useSelector((state) => selectSearchedSlice(state, searchQuery));

  /** Form */
  const isTrainingsListLoaded = useSelector(selectIsTrainingsListLoaded);
  const formProps = {
    title: !isTrainingsListLoaded ? `${__('app.Loading in progress')}...` : null,
    onSubmit: (e) => {
      e.preventDefault();
      const query = new FormData(e.target).get('search');
      setSearchQuery(query);
    },
  };

  /** Open/close the overlay */
  useEffect(() => {
    if (searchResults.current.length > 0) {
      menuState.open();
    } else {
      menuState.close();
    }
  }, [searchResults, menuState]);

  return (
    <>
      <form {...formProps} className="form header-form header-form-search">
        <FieldSearch
          name="search"
          label={__('menu.Search')}
          placeholder={__('menu.Search')}
          clearButtonLabel={__('buttons.Clear')}
          autoComplete="off"
          onSubmit={(query) => setSearchQuery(query)}
          onKeyUp={(e) => setSearchQuery(e.currentTarget.value)}
          isDisabled={!isTrainingsListLoaded}
        />
        <Button
          {...buttonProps}
          ref={buttonRef}
          className="submit-button"
          type="submit"
          icon="search"
          isDisabled={!isTrainingsListLoaded}
          ariaLabel={__('buttons.Send')}
        />
      </form>

      {menuState.isOpen && (
        <Overlay
          props={menuProps}
          searchResults={searchResults.current}
          autoFocus={menuState.focusStrategy}
          onClose={() => {
            menuState.close();
            setSearchQuery('');
          }}
        />
      )}
    </>
  );
};

const Overlay = (props) => {
  const { searchResults } = props;
  // const { navigate } = useNavigateWithLoader();
  // const { t: __ } = useTranslation();

  const overlayRef = useRef();
  const closeWithTransition = useCloseWithTransition(overlayRef, props.onClose, 250);
  const { overlayProps } = useOverlay(
    {
      onClose: closeWithTransition,
      shouldCloseOnBlur: true,
      isOpen: true,
      isDismissable: true,
    },
    overlayRef
  );

  const perPage = 8;
  const numberPages = Math.ceil(searchResults.length / perPage);

  const [currentPage, setCurrentPage] = useState(1);
  const [searchResultsSlice, setSearchResultsSlice] = useState([]);

  useEffect(() => {
    setSearchResultsSlice(searchResults.slice((currentPage - 1) * perPage, currentPage * perPage));
  }, [setSearchResultsSlice, searchResults, currentPage]);

  return (
    <FocusScope restoreFocus>
      <div ref={overlayRef} {...overlayProps} className="header-form-search-overlay">
        <DismissButton onDismiss={props.onClose} />
        <TrainingsTable trainingsList={searchResultsSlice} onNavigate={closeWithTransition} />
        <Pagination
          currentPage={currentPage}
          numberPages={numberPages}
          onPrev={() => setCurrentPage(currentPage - 1)}
          onNext={() => setCurrentPage(currentPage + 1)}
        />

        {/*<Menu
            onAction={(link) => {
              closeWithTransition();
              navigate(link);
            }}
            aria-label={__('menu.Search results')}
          >
            {searchResults.map((training, index) => {
              const singleTrainingLink = `${pageTrainingLink}${kebabCase(training.intitule)}-${kebabCase(training.niveau)}-${kebabCase(training.coursId)}-${kebabCase(training.anneeScolaire)}/`;
              return (
                <Item key={singleTrainingLink} textValue={training.matiereNom}>
                  <Link url={singleTrainingLink} onPress={() => {}} title={training.matiereNom} />
                </Item>
              );
            })}
          </Menu>*/}
        <DismissButton onDismiss={props.onClose} />
      </div>
    </FocusScope>
  );
};

Overlay.propTypes = {
  searchResults: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default FormSearch;
