import { useEffect, useState } from 'react';

/** Utils */
import { responseStatus } from '../util/Fetch';

/**
 * useCsrfToken Hook
 *
 * Get a csrf token and the wp_referer field value from the app.
 *
 * @param {string} tokenAction  The token identifier
 * @return {Object} {csrfToken: string, wpReferer: string}  The csrf token and the wp_referer fields values
 */
const useCsrfToken = (tokenAction) => {
  const [values, setValues] = useState({ csrfToken: '', wpReferer: '' });

  /** Fetch the values from the app */
  useEffect(() => {
    fetch(`${process.env.REACT_APP_THEME_URL}resources/get-token.php`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'X-CSRF-TOKEN': tokenAction,
      },
    })
      .then(responseStatus)
      .then((response) => response.json())
      .then((response) => setValues({ csrfToken: response.token, wpReferer: response.referer }))
      .catch(console.error);
  }, [tokenAction, setValues]);

  /** Return the values */
  return values;
};

export { useCsrfToken };
export default useCsrfToken;
