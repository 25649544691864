import PropTypes from 'prop-types';

export const Image = ({ layout, src, image, format, sizes, alt, onLoad }) => {
  const srcProps =
    image && format && sizes
      ? {
          src: Object.values(image[format]).pop(),
          srcSet: Object.entries(image[format])
            .map(([size, image]) => `${image} ${size.substring(1, size.indexOf('h'))}w`)
            .join(','),
          sizes,
        }
      : {
          src,
        };

  return (
    <div className="image">
      <img
        {...srcProps}
        className={`${layout ? `l-${layout}` : ''} ${
          layout === 'l-image-illustration' ? 'float-right width-full' : ''
        }`}
        alt={alt}
        onLoad={(e) => (onLoad !== undefined ? onLoad(e) : () => {})}
      />
    </div>
  );
};

/**
 * Load an image and fire the callback when loaded
 *
 * @param {string|imagePropTypes} image  The image string or object
 * @param {function} callback  The callback function
 * @param {string} format  landscape|square  The image format from imagePropTypes
 *
 * If you provide an image object, you have to provide the format too
 */
export const imageLoader = (image, callback, format) => {
  const src =
    typeof image === 'string'
      ? image
      : typeof image === 'object' && format !== undefined
      ? Object.values(image[format]).pop()
      : null;

  if (src) {
    const imageLoader = document.createElement('img');
    imageLoader.src = src;
    imageLoader.addEventListener('load', callback);
  } else {
    callback();
  }
};

export const imagePropTypes = {
  image: PropTypes.shape({
    title: PropTypes.string.isRequired,
    landscape: PropTypes.shape({
      w3840h2560: PropTypes.string,
      w2880h1920: PropTypes.string,
      w1920h1280: PropTypes.string,
      w1440h960: PropTypes.string,
      w960h640: PropTypes.string,
    }).isRequired,
    square: PropTypes.shape({
      w1920h1920: PropTypes.string,
      w1440h1440: PropTypes.string,
      w960h960: PropTypes.string,
    }).isRequired,
  }),
};

/**
 * You have to provide either
 * - the src prop
 * - the image, format and sizes props
 */
Image.propTypes = {
  ...imagePropTypes,
  layout: PropTypes.oneOf([]),
  src: PropTypes.string,
  format: PropTypes.string,
  sizes: PropTypes.string,
  alt: PropTypes.string.isRequired,
  onLoad: PropTypes.func,
};

export default Image;
