import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

/** Utils */
import { FormSender } from '../util/Fetch';
import { formatDate } from '../util/Date';

export const sendFormData = createAsyncThunk('form/sendFormData', async (formData, { rejectWithValue }) => {
  return new Promise((resolve, reject) => {
    const sender = new FormSender(
      formData,
      `${process.env.REACT_APP_THEME_URL}resources/post-form-presubscription.php`
    );
    sender.addHeader('X-CSRF-TOKEN', formData.get('csrf-token'));

    sender.addListener('response', (data) => {
      if (data.sent) {
        resolve(data);
      } else {
        reject(data.error);
      }
    });

    sender.send();
  }).catch((error) => rejectWithValue(error));
});

const formSlice = createSlice({
  name: 'form',
  initialState: {
    formData: null,
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sendFormData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(sendFormData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.formData = action.payload;

        const intitule = action.meta.arg.get('courseTitle');
        const coursId = action.meta.arg.get('coursId');
        const categorieNom = action.meta.arg.get('categorieNom');
        const matiereNom = action.meta.arg.get('matiereNom');
        const langueCoursCode = action.meta.arg.get('langueCoursCode');
        const dateDebut = action.meta.arg.get('dateDebut');
        const dateFin = action.meta.arg.get('dateFin');

        if (window && window.dataLayer) {
          window.dataLayer.push({
            event: 'send',
            label: 'registration_request_form',
            course_name: intitule,
            course_code: coursId,
            course_field: `${categorieNom} - ${matiereNom}`,
            course_language: langueCoursCode,
            course_dates: `${formatDate(dateDebut)} - ${formatDate(dateFin)}`,
          });
        }
      })
      .addCase(sendFormData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default formSlice.reducer;
