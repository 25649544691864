import PropTypes from 'prop-types';
import { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

/** Redux */
import { selectTrainingPages } from '../../redux/pagesSlice';

/** Hooks */
import { useResponsiveImage } from '../../hooks/useResponsiveImage';

/** Utils */
import { imageLoader } from '../Image';

/** Components */
import Container from '../Container';
//import Button from '../Button';
import { linkPropTypes, Link } from '../Link';
import LogoUnipop from '../../images/LogoUnipop';

const PageHeader = (props) => {
  const { template, content, linkRef } = props;
  const { t: __ } = useTranslation();
  const transProps = { __ };

  return (
    <>
      {(content.title || content.text || content.headline) && (
        <header className={`page-header ${template.id !== 'front-page' ? 'printable' : ''}`}>
          {template.id === 'front-page' ? (
            <FrontPageHeader {...props} {...transProps} linkRef={linkRef} />
          ) : template.id === 'single-training' ? (
            <SingleTrainingHeader {...props} {...transProps} />
          ) : template.id.startsWith('trainings') && template.id !== 'trainings' ? (
            <TrainingsPagesHeader {...props} {...transProps} />
          ) : (
            <DefaultHeader {...props} {...transProps} />
          )}
        </header>
      )}
    </>
  );
};

const FrontPageHeader = ({ content, __, linkRef }) => {
  const { title, headline, text, image, link } = content;

  const contentRef = useRef();
  const responsiveImage = useResponsiveImage(image, 'pageHeader');

  useEffect(() => {
    imageLoader(responsiveImage, () => contentRef.current && contentRef.current.classList.remove('is-loading'));
  }, [contentRef, responsiveImage]);

  const fullImageUrl = image.w3840h1040 ?? responsiveImage ?? '';
  const hasLink = link && link.url;
  return (
    <Container layout="large">
      <div className="page-header-headline-top">
        <div className="content-top">
          <h2 className="content-top-title">
            <span dangerouslySetInnerHTML={{ __html: title }} />
          </h2>
        </div>
      </div>
      <div className="page-header-headline">
        <div ref={contentRef} className="content is-loading">
          <div className="content-title">
            <span dangerouslySetInnerHTML={{ __html: title }} />
          </div>
          <div className="content-text" style={{ backgroundImage: `url('${fullImageUrl}')` }}>
            {(headline || link) && (
              <p>
                {headline && (
                  <>
                    <span dangerouslySetInnerHTML={{ __html: headline }} />
                    {!headline.endsWith('.') && <span className="visually-hidden">.</span>}
                  </>
                )}
                {hasLink && (
                  <Link
                    ref={linkRef}
                    layout="button-square-outline"
                    url={link.url}
                    title={__('buttons.Find out more')}
                  />
                )}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="page-header-content">
        <div className="content">
          <p className="content-text" dangerouslySetInnerHTML={{ __html: text }} />
        </div>
      </div>
    </Container>
  );
};

const PageHeaderImage = ({ image }) => {
  const imageRef = useRef();
  const responsiveImage = useResponsiveImage(image, 'pageHeader');

  useEffect(() => {
    imageLoader(responsiveImage, () => imageRef.current && imageRef.current.classList.remove('is-loading'));
  }, [imageRef, responsiveImage]);

  return (
    <div
      ref={imageRef}
      className="page-header-image is-loading"
      style={{ backgroundImage: `url(${responsiveImage})` }}
    />
  );
};

const SingleTrainingHeader = ({ content }) => {
  const { title, text } = content;

  return (
    <>
      <div className="page-header-content">
        <Container>
          <div className="content">
            <p className="content-title" dangerouslySetInnerHTML={{ __html: title }} />
            <div className="content-text" dangerouslySetInnerHTML={{ __html: text }} />
          </div>
        </Container>
      </div>
    </>
  );
};

const TrainingsPagesHeader = ({ content, __ }) => {
  const { title, text } = content;

  const { i18n } = useTranslation();

  const trainingsPages = useSelector((state) => selectTrainingPages(state, i18n.language));
  const currentPage = useSelector((state) => state.pages.currentPage);
  const currentPageIndex = trainingsPages.findIndex((page) => page.template.id === currentPage.template.id) + 1;

  return (
    <>
      <div className="page-header-content">
        <Container>
          <div className="content">
            <div className="content-headline">
              <p className="content-domain">
                {__('templates.trainings.Domain')} {currentPageIndex}
              </p>
              <h2 className="content-title" dangerouslySetInnerHTML={{ __html: title }} />
            </div>
            <div className="content-text">
              <div dangerouslySetInnerHTML={{ __html: text }} />
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

const DefaultHeader = ({ template, content }) => {
  const { title, headline, text, link, image } = content;

  return (
    <>
      {image && <PageHeaderImage image={image} />}
      <div className="page-header-content">
        <Container>
          <div className="content">
            <div className="content-headline">
              <h2 className="content-title" dangerouslySetInnerHTML={{ __html: title }} />
              {!headline && <div className="content-text" dangerouslySetInnerHTML={{ __html: text }} />}
              {(template.id === 'unipop' || template.id === 'page') && <LogoUnipop />}
            </div>
            {headline ? (
              <div className="content-cols">
                <div className="content-cols-text">
                  <div dangerouslySetInnerHTML={{ __html: text }} />
                  {link && <Link layout="button-plain" url={link.url} target={link.target} title={link.title} />}
                </div>
                <div className="content-cols-text" dangerouslySetInnerHTML={{ __html: headline }} />
              </div>
            ) : (
              link && (
                <div className="content-cols l-link">
                  <div className="content-cols-text">
                    <Link layout="button-plain" url={link.url} target={link.target} title={link.title} />
                  </div>
                </div>
              )
            )}
          </div>
        </Container>
      </div>
    </>
  );
};

const imagePropTypes = {
  image: PropTypes.shape({
    w3840h1040: PropTypes.string.isRequired,
    w2880h780: PropTypes.string.isRequired,
    w1920h520: PropTypes.string.isRequired,
    w960h260: PropTypes.string.isRequired,
  }),
};

const propTypes = {
  template: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }),
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    headline: PropTypes.string,
    text: PropTypes.string.isRequired,
    link: PropTypes.oneOfType([PropTypes.string, PropTypes.shape(linkPropTypes)]),
    image: PropTypes.shape({
      w3840h1040: PropTypes.string.isRequired,
      w2880h780: PropTypes.string.isRequired,
      w1920h520: PropTypes.string.isRequired,
      w960h260: PropTypes.string.isRequired,
    }),
  }),
  __: PropTypes.func,
  linkRef: PropTypes.object,
};

PageHeader.propTypes = propTypes;
PageHeaderImage.propTypes = imagePropTypes;
FrontPageHeader.propTypes = propTypes;
SingleTrainingHeader.propTypes = propTypes;
TrainingsPagesHeader.propTypes = propTypes;
DefaultHeader.propTypes = propTypes;

export default PageHeader;
