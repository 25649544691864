import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

/** Hooks */
import { useReCaptcha } from '../../hooks/useReCaptcha';
import { useCsrfToken } from '../../hooks/useCsrfToken';

/** Utils */
import { FormSender } from '../../util/Fetch';

/** Components */
import TextField from './TextField';
import FieldText from './FieldText';
import Button from '../Button';
import IconBack from '../../images/icon-back';

const ContactForm = ({ onHideBack }) => {
  const { t: __ } = useTranslation();
  const formRef = useRef(null);
  const formProps = {
    action: `${process.env.REACT_APP_THEME_URL}resources/post-form-contact.php`,
    method: 'POST',
    onSubmit: (e) => submitForm(e),
  };

  /** CSRF Token & WP Referer */
  const { csrfToken, wpReferer } = useCsrfToken('post-form-contact');

  /** reCAPTCHA */
  const reCaptchaKey = useSelector((state) => state.contacts.settings.recaptcha_key);
  const loadReCaptcha = useReCaptcha(reCaptchaKey);

  /** Messages */
  const [errorMessage, setErrorMessage] = useState('');
  const [sentMessage, setSentMessage] = useState('');
  const [descriptionMessage, setDescriptionMessage] = useState('');
  const [isSent, setIsSent] = useState(false);

  /**
   * Submit the form
   *
   * Check Google reCAPTCHA and then send the form
   *
   * @param {SubmitEvent} e  The form submit event
   */
  const submitForm = (e) => {
    e.preventDefault();

    // Perform validation
    const form = formRef.current;
    const lastName = form.last_name.value.trim();
    const firstName = form.first_name.value.trim();
    const email = form.email.value.trim();
    const message = form.message.value.trim();

    if (!lastName || !firstName || !email || !message) {
      setDescriptionMessage(__('forms.All fields are required'));
      return;
    }

    window.grecaptcha.ready(() =>
      window.grecaptcha.execute(reCaptchaKey, { action: 'contact' }).then((token) => sendForm(token))
    );
  };

  /**
   * Send the form
   *
   * @param {string} token  The reCAPTCHA token
   */
  const sendForm = (token) => {
    /** Set the form data */
    const formData = new FormData(formRef.current);
    formData.append('token', token);
    formData.append('csrf-token', csrfToken);
    formData.append('_wp_http_referer', wpReferer);

    /** Send the form */
    const sender = new FormSender(formData, formProps.action);
    sender.addHeader('X-CSRF-TOKEN', csrfToken);
    sender.addListener('response', (data) => onSenderResponse(data));
    sender.send();

    /** Set the loading message */
    setDescriptionMessage(__('forms.Sending in progress'));
  };

  /**
   * Process the form sender response
   *
   * @param {JSON} response
   */
  const onSenderResponse = (response) => {
    if (response.sent && response.data.sent) {
      setSentMessage(__('forms.Your message has been sent, thank you.'));
      setIsSent(true);
      if (window && window.dataLayer) {
        window.dataLayer.push(function () {
          this.reset();
        });
        window.dataLayer.push({
          event: 'send',
          label: 'contact_form',
          title: __('buttons.Send'),
        });
      }
      setTimeout(() => {
        setSentMessage('');
        setIsSent(false);
        formRef.current.reset();
        setDescriptionMessage('');
        setErrorMessage('');
      }, 5000);
    } else {
      setDescriptionMessage('');
      setErrorMessage(__('forms.Error sending your message, please try later.'));
    }
  };

  return (
    <div className="contectbot-form-content" tabIndex="-1">
      <button onClick={onHideBack} className="back">
        <IconBack />
      </button>
      <div className="contactbot-header">
        {isSent ? __('contactbot.Bravo') : __('contactbot.title_3')}
        <br />
        {isSent ? '' : __('contactbot.title_4')}
      </div>
      <form className="contectbot-form text-black" ref={formRef} onSubmit={submitForm}>
        <div className="flex flex-row justify-between form header-form header-form-contact">
          {sentMessage.length === 0 ? (
            <>
              <FieldText
                type="text"
                name="last_name"
                label={__('contacts.lastname')}
                placeholder={__('contacts.lastname')}
                inputClassName="w-1/2"
                labelProps={{ style: { display: 'none' } }}
                isRequired={true}
              />
              <FieldText
                type="text"
                name="first_name"
                label={__('contacts.firstname')}
                placeholder={__('contacts.firstname')}
                inputClassName="w-1/2 text-black"
                labelProps={{ style: { display: 'none' } }}
                isRequired={true}
              />
              <FieldText
                type="email"
                name="email"
                label={__('contacts.email')}
                placeholder={__('contacts.email')}
                inputClassName="w-full text-black"
                labelProps={{ /*required: false, */ style: { display: 'none' } }}
                isRequired={true}
                onFocus={loadReCaptcha}
              />
              <TextField
                name="message"
                label={__('contacts.message')}
                placeholder={__('contacts.message')}
                inputClassName="w-full"
                labelProps={{ style: { display: 'none' } }}
                description={descriptionMessage}
                errorMessage={errorMessage}
                isRequired={true}
              />
              <Button className="submit-button" layout="square-outline" type="submit" ariaLabel={__('buttons.Send')}>
                {__('buttons.Send')}
              </Button>
            </>
          ) : (
            <p className="form-confirmation">{sentMessage}</p>
          )}
        </div>
      </form>
    </div>
  );
};

ContactForm.propTypes = {
  onHideBack: PropTypes.func.isRequired,
};

export default ContactForm;
