import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

/** Hooks */
import { useReCaptcha } from '../../../hooks/useReCaptcha';
import { useCsrfToken } from '../../../hooks/useCsrfToken';

/** Utils */
import { FormSender } from '../../../util/Fetch';

/** Components */
import FieldText from '../../forms/FieldText';
import Button from '../../Button';

const FormNewsletter = () => {
  const { t: __ } = useTranslation();

  /** Form */
  const formRef = useRef();
  const formProps = {
    action: `${process.env.REACT_APP_THEME_URL}resources/post-form-newsletter.php`,
    method: 'POST',
    onSubmit: (e) => submitForm(e),
  };

  /** CSRF Token & WP Referer */
  const { csrfToken, wpReferer } = useCsrfToken('post-form-newsletter');

  /** reCAPTCHA */
  const reCaptchaKey = useSelector((state) => state.contacts.settings.recaptcha_key);
  const loadReCaptcha = useReCaptcha(reCaptchaKey);

  /** Messages */
  const [errorMessage, setErrorMessage] = useState('');
  const [sentMessage, setSentMessage] = useState('');
  const [descriptionMessage, setDescriptionMessage] = useState('');

  /**
   * Submit the form
   *
   * Check Google reCAPTCHA and then send the form
   *
   * @param {SubmitEvent} e  The form submit event
   */
  const submitForm = (e) => {
    e.preventDefault();
    window.grecaptcha.ready(() =>
      window.grecaptcha.execute(reCaptchaKey, { action: 'contact' }).then((token) => sendForm(token))
    );
  };

  /**
   * Send the form
   *
   * @param {string} token  The reCAPTCHA token
   */
  const sendForm = (token) => {
    /** Set the form data */
    const formData = new FormData(formRef.current);
    formData.append('csrf-token', csrfToken);
    formData.append('token', token);
    formData.append('_wp_http_referer', wpReferer);

    /** Send the form */
    const sender = new FormSender(formData, formProps.action);
    sender.addHeader('X-CSRF-TOKEN', csrfToken);
    sender.addListener('response', (data) => onSenderResponse(data));
    sender.send();

    /** Set the loading message */
    setDescriptionMessage(__('forms.Sending in progress'));
  };

  /**
   * Process the form sender response
   *
   * @param {JSON} response
   */
  const onSenderResponse = (response) => {
    if (response.sent && response.data.sent) {
      setSentMessage(__('forms.Your email has been registered, thank you.'));
      if (window && window.dataLayer) {
        window.dataLayer.push(function () {
          this.reset();
        });
        window.dataLayer.push({
          event: 'subscribe',
          label: 'newsletter',
          title: __('buttons.Send'),
        });
      }
    } else {
      setDescriptionMessage('');
      setErrorMessage(__('forms.Error registering your e-mail, please try later.'));
    }
  };

  return (
    <form ref={formRef} {...formProps} className="form header-form header-form-newsletter">
      {sentMessage.length === 0 ? (
        <>
          <FieldText
            type="email"
            name="newsletter"
            label={__('forms.Newsletter SIGN UP')}
            placeholder={__('forms.Your email')}
            onFocus={loadReCaptcha}
            description={descriptionMessage}
            errorMessage={errorMessage}
          />
          <Button className="submit-button" type="submit" icon="arrow-right" ariaLabel={__('buttons.Send')} />
        </>
      ) : (
        <p className="form-confirmation">{sentMessage}</p>
      )}
    </form>
  );
};

export default FormNewsletter;
