import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

const TrackableButton = forwardRef(
  ({ className, type, title, url, onClick, event_name, event_data = {}, children, ...props }, buttonRef) => {
    const handleGTMEvent = () => {
      if (window && window.dataLayer && event_name) {
        window.dataLayer.push(function () {
          this.reset();
        });

        const label = event_name ? event_data.label : typeof children === 'string' ? children : title || url;
        const defaultData = {
          title,
          label,
          outbound: false,
        };
        const dataLayerEvent = {
          event: event_name || 'onsite_click',
          ...defaultData,
          ...event_data,
        };

        window.dataLayer.push(dataLayerEvent);
      }
    };

    const handleClick = (e) => {
      handleGTMEvent();
      if (onClick) {
        onClick(e);
      }
    };

    return (
      <button type={type} ref={buttonRef} className={`button ${className}`} onClick={handleClick} {...props}>
        {children}
      </button>
    );
  }
);

TrackableButton.displayName = 'TrackableButton';

TrackableButton.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
  onClick: PropTypes.func,
  event_name: PropTypes.string,
  event_data: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

TrackableButton.defaultProps = {
  className: '',
  type: 'button',
  title: '',
  url: '',
  onClick: () => {},
  event_name: '',
  event_data: {},
};

export default TrackableButton;
