import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { useButton } from '@react-aria/button';

/** Components */
import Icon from './Icon';

const Button = forwardRef((props, buttonRef) => {
  const { buttonProps } = useButton(props, buttonRef);
  const {
    children,
    icon,
    ariaLabel,
    className,
    layout,
    iconLayout,
    onMouseEnter,
    onMouseLeave,
    title,
    url,
    event_name,
    event_data,
    onClick,
  } = props;

  const handleGTMEvent = () => {
    if (window && window.dataLayer && event_name) {
      window.dataLayer.push(function () {
        this.reset();
      });

      const label = event_name ? event_data?.label : typeof children === 'string' ? children : title || url;
      const defaultData = {
        title,
        label,
        outbound: false,
      };
      const dataLayerEvent = {
        event: event_name || 'button_click',
        ...defaultData,
        ...event_data,
      };

      window.dataLayer.push(dataLayerEvent);
    }
  };

  const handleClick = (e) => {
    handleGTMEvent();
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <button
      ref={buttonRef}
      {...buttonProps}
      aria-label={ariaLabel || null}
      className={`button ${className} ${layout ? `l-${layout}` : ''}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={handleClick}
    >
      {children ? children : icon && <Icon icon={icon} layout={iconLayout} />}
    </button>
  );
});

Button.displayName = 'Button';

/**
 * `children` and `icon` props can't be set together.
 * Don't forget to provide an `ariaLabel` prop if you provide the `icon` prop.
 */
Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  icon: PropTypes.string,
  layout: PropTypes.oneOf(['square-outline', 'plain']),
  iconLayout: PropTypes.string, // @see Icon.propTypes
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  ariaLabel: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
  event_name: PropTypes.string,
  event_data: PropTypes.object,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  className: '',
  onMouseEnter: () => {},
  onMouseLeave: () => {},
  title: '',
  url: '',
  event_name: '',
  event_data: {},
  onClick: () => {},
};

export default Button;
